import React from 'react'

import EstimateBtn from './EstimateBtn'
import MenuBtn from './MenuBtn'

import '../../styles/components/overlay/overlay.scss'

export default () => {
  return (
    <div className='overlay'>
      <EstimateBtn />
      <MenuBtn />
    </div>
  )
}