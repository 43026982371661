import React, {useEffect} from 'react'

import '../styles/components/contact-up-buttons.scss'

import PhoneIcon from '../../static/icons/phone.svg'
import PdfIcon from '../../static/icons/pdf.svg'

export default () => {
  const animatePhone = button => {
    if (button.classList.contains('animation')) return

    const rect = button.getBoundingClientRect()
    if (rect.top > window.innerHeight) return

    const anim = state => state ?
      button.classList.add('animation') :
      button.classList.remove('animation')

    anim(true); setTimeout(anim, 1000, false)
  }

  const onScheduleClick = event => {
    event.preventDefault()
    Calendly && Calendly.initPopupWidget({
      url: 'https://calendly.com/evgeny-pinigin/30min'
    })

    const selector = '.calendly-popup iframe'
    const frame = document.querySelector(selector)

    const onFrameLoad = () => {
      frame.removeEventListener('load', onFrameLoad)
      const selector = '.calendly-popup-close'
      const close = document.querySelector(selector)
      close.classList.add('active')
    }

    frame.addEventListener('load', onFrameLoad)
  }
  const onDownloadClick = () => {
    const link = document.createElement('a')
    link.setAttribute('href', 'docs/Zimalab 2022 - Upwork PDF.pdf')
    link.setAttribute('download', '')
    link.click()
    link.remove()
  }
  const onScheduleEnter = event => {
    animatePhone(event.currentTarget)
  }

  // Start animation on timer
  useEffect(() => {
    const attribute = 'data-schedule-animation'
    const html = document.querySelector('html')
    if (html.getAttribute(attribute)) return
    html.setAttribute(attribute, 'true')

    setInterval(() => document
        .querySelectorAll('.schedule-call')
        .forEach(animatePhone)
    , 3000)
  }, [])

  return (
    <div className="contact-up-buttons">

      <button
        onMouseEnter={ onScheduleEnter }
        onClick={ onScheduleClick }
        className="schedule-call"
      >
        Schedule a Call
        <PhoneIcon/>
      </button>

      <button
        onClick={ onDownloadClick }
        className="download-pdf"
      >
        Download a PDF
        <PdfIcon/>
      </button>

    </div>
  )
}
