export const menuItems = [
  {
    title: "Home",
    link: "home"
  },
  {
    title: "What We Do",
    link: "what-we-do"
  },
  {
    title: "Our Work",
    link: "/portfolio/"
  },
  {
    title: "Our Team",
    link: "our-team"
  },
  {
    title: "Our Services",
    link: "/services/"
  },
  {
    title: "Our Clients",
    link: "our-clients"
  },
  {
    title: "Reviews",
    link: "reviews"
  },
  {
    title: "Contact Us",
    link: "contact-us"
  },

]

export const servicesItems = [
  {
    title: 'Backend',
    text: 'Team of professional PHP developers. Strong specialisation allows us to solve wide range of tasks from building simple CMS solutions to large ERP systems.',
    points: [
      'Modern frameworks',
      'Clean well-structured code',
      'Advanced tools'
    ],
    anchor: 'Backend'
  },
  {
    title: 'Frontend',
    text: 'Deliver great User Experience with lightning fast and interactive frontend with modern JS frameworks like Vue and React.',
    points: [
      'Responsive HTML/CSS',
      'Modern Javascript frameworks',
      'SPA & PWA applications',
      'Templates & UI kits'
    ],
    anchor: 'Frontend'
  },
  {
    title: 'API',
    text: 'Extend functionality of your web app with 3rd party integrations, sync work of different services or create your own API for mobile apps or another services.',
    points: [
      'API integrations',
      'API development',
      'API connections'
    ],
    anchor: 'API'
  },
  {
    title: 'UI/UX design',
    text: 'Our design team will design User Interface according to your vision and business goals and foresee all use cases including main user stories, negative use cases, empty states and wrong data.',
    points: [
      'Wireframes',
      'Graphical design',
      'Interactive UI prototype'
    ],
    anchor: 'UI/UX'
  },
  {
    title: 'Discovery',
    text: 'Together with Business Analyst and Lead Developer, we define implementation approach, functional requirements, and detailed development plan, according to your budget, desired timeline and business goals.',
    points: [
      'Business Analytics',
      'Feasibility and Architecture',
      'Specifications Creation'
    ],
    anchor: 'Discovery'
  },
  {
    title: 'Going live',
    text: 'We will help you to prepare for release and provide you with on-going development services and maintenance for long term growth',
    points: [
      'Release preparation',
      'Deployment',
      'On-going support'
    ],
    anchor: 'Live&Maintenance'
  },
  {
    title: 'Featured',
    text: 'We focus on high quality and attention to the details and you will be able to take advantage of additional services we provide.',
    points: [
      'Speed Optimization',
      'QA, code audit & compliance',
      'Security'
    ],
    anchor: 'Featured'
  },
]

export const workItems = [
  {
    title: 'security',
    content: 'SaaS solution to manage ads and threats blocking lists to reduce malware infection and other security vulnerabilities.',
    link: '/portfolio/spp/'
  },
  {
    title: 'finance',
    content: 'Real-time dashboard with personalized signals for currency trading and subscription-based access.',
    link: '/portfolio/traderadius/'
  },
  {
    title: 'auto',
    content: 'CRM/ERP solution for a chain of 500+ car bodyshops. Dashboard for orders and supply management, different access level for shops, statistics module and generation .pdf reports.',
    link: '/portfolio/bodyshops/'
  },
  {
    title: 'education',
    content: 'A portal for easy search for teachers and tutors nearby.',
    link: '/portfolio/lessonsaustralia/'
  },
  {
    title: 'music',
    content: 'Musical portal for learning how to play piano and other instruments. Different membership plans. Songs, artists and cources directory.',
    link: '/portfolio/negina/'
  }
]

export const reviews = [
  {
    name: 'Deniz from Germany',
    position: 'Head of Marketing Automations Agency',
    review: `Zimalab are a brilliant company who I've been using for years. Reasonable, fair and always get the job done. Highly recommend.`
  },
  {
    name: 'Peter from Australia',
    position: 'Design Engineer at Engineering Company',
    review: `They were great about turning around results as our requirements changed. They rarely said, “We can’t do that.”`
  },
  {
    name: 'Rob from Netherlands',
    position: 'Cofounder of Travel Startup',
    review: `Zimalab rapidly delivered a flawless end product that seamlessly integrates with compatible systems. Communication was strong and occurred on a daily basis.`
  },
  {
    name: 'Evan from USA',
    position: 'Web Architect of Distribution Company',
    review: `These guys are great. I have worked with them for a long time and I am in the process of starting multiple projects with them again.`
  },
  {
    name: 'Oliver from Germany',
    position: 'Founder of Cleaning Startup',
    review: `Zimalab is doing an absolutely amazing job! We worked with a full-scale website development from scratch and did so in a fairly agile fashion for around 3 months.`
  },
  {
    name: 'Patrick from UK',
    position: 'IT Consultant',
    review: `This was one of my first experiences with sourcing developers outside of the UK. The created website is really slick and everyone is very happy with it. Great agency, with a talented team behind them.`
  }
]

export const OurApproachData = [
  {
    number: 1,
    title: 'Expertise',
    description: [
      'specialize in PHP (35,000+ hours worked);',
      'can build robust web app architecture and solve complex tasks;',
      'easy to share experience in our team.'
    ]
  },
  {
    number: 2,
    title: 'Planning',
    description: [
      "compose specs, describe features in detail to make a development plan;",
      "investigate feasibility, divide project into phases;",
      "come up with realistic timeline and budget cost estimates.",
    ]
  },
  {
    number: 3,
    title: 'Management',
    description: [
      'the project manager is in touch all the day;',
      'budget and work schedule are under control;',
      "you don't need to waste your time talking with every team member about each request."
    ]
  },
  {
    number: 4,
    title: 'Demos and reports',
    description: [
      "any time you can check the results on demo server;",
      "we make progress reports on weekly basis;",
      "the next sprints are planned according to the progress."
    ]
  }
]

export const teamItems = [
  {
    name: 'Evgeny', position: 'ceo', avatar: 'Evgeny', tags: ['travel', 'startups', 'football'],
    about: 'CEO & Co-founder with 10 years experience in IT consulting and web apps development business',
  },
  {
    name: 'Innokentiy', position: 'cto', avatar: 'Innokentiy', tags: ['php', 'gym'],
    about: 'CTO, Cofounder & Senior PHP developer with 10+ years experience in commercial web development',
  },
  {
    name: 'Serezha', position: 'senior developer', avatar: 'Sergey', tags: ['cycling', 'esports', 'moustache'],
    about: 'Senior PHP Developer with 5+ years experience in web apps creation and system architecture',
  },
  {
    name: 'Timofey', position: 'senior developer', avatar: 'Timofey', tags: ['php', 'karaoke', 'anime'],
    about: 'Senior PHP Developer with 6+ years experience in web apps development and architecture',
  },
  {
    name: 'Ilya', position: 'project manager', avatar: 'Ilyusha', tags: ['scrum', 'football', 'digitalnomad'],
    about: 'Agile & Scrum English speaking project manager with developer’s background and tech skills',
  },
  {
    name: 'Roman', position: 'full stack developer', avatar: 'Roman', tags: ['cooking', 'running', 'photography'],
    about: 'Full Stack Developer with 5+ years experience in web apps creation and API integrations',
  },
]

export const portfolioItems = [
  {
    title: 'Music lessons',
    category: 'Music',
    background: 'portfolio/guitar',
    project: 'negina',
    featured: true,
    about: 'A portal for learning how to play music instruments with paid subscriptions, songs and artists catalog',
    items: [
      'Online directory',
      'Interactive piano tool',
      'Subscriptions',
      'RTL support',
      'Speed optimization',
    ],
    icons: [
      'isSymfony',
      'isJs',
      'isApi',
      'isHtml',
      'isSpeed',
    ],
    extra: null
  },
  {
    title: 'Auto Repair ERP',
    category: 'auto',
    background: 'landing/our-work/auto',
    project: 'bodyshops',
    featured: true,
    about: 'A CRM/ERP solution for a chain of 500+ car bodyshops. Dashboard for orders and supply management, different access level for shops, statistics module and generation .pdf reports.',
    items: [
      'Orders & Supply',
      'Roles management',
      'Production dashboard',
      'Work load calendar',
      'SMS & email alerts',
    ],
    icons: [
      'isSquare',
      'isNote',
      'isSymfony',
      'isJs',
      'isHtml',
    ],
    extra: null
  },
  {
    title: 'Hotel Booking',
    category: 'travel',
    background: 'landing/our-work/travel',
    project: 'powernapp',
    featured: true,
    about: 'A web application for booking hotels with special offers for SME and integrations with popular hotel channels. Real-time availability and prices updates based on certified integrations with RezExchange, Smarthotels and Cubilis.',
    items: [
      'Hotels & Bookers dashboard',
      'Channel API integrations',
      'Real-time reservations sync',
      'Custom hotel search engine',
    ],
    icons: [
      'isScreen',
      'isNote',
      'isSymfony',
      'isJs',
      'isHtml',
      'isApi',
    ],
    extra: null
  },
  {
    title: 'Trading signals platform',
    category: 'finance',
    background: 'portfolio/market',
    project: 'traderadius',
    featured: true,
    about: 'Real-time dashboard with personalized signals for currency trading and subscription-based access.',
    items: [
      'Customizable dashboards',
      'Data widgets and charts',
      'News portal',
      'Subscriptions',
      'Notifications system',
    ],
    icons: [
      'isHtml',
      'isJs',
      'isSymfony',
      'isScreen',
      'isNote',
      'isApi',
    ],
    extra: {
      image: 'traderadius',
      stats: [
        { value: 750, text: 'hours' },
        { value: 4, text: 'months to MVP' },
        { value: 3, text: 'developers' },
      ]
    }
  },
  {
    title: 'eBay Integration',
    category: 'retail',
    background: 'portfolio/notebook',
    project: null,
    featured: false,
    about: 'Automatically publish items on eBay from Pawn marketplace database. Monitor sales status and retreive info about sold items.',
    items: null,
    icons: [
      'isPhp',
      'isApi',
    ],
    extra: null
  },
  {
    title: 'Schools Directory',
    category: 'education',
    background: 'portfolio/books',
    project: null,
    featured: false,
    about: 'A portal for students and parents to discover and compare private schools. Implemented in 2 languages — English and Arabic.',
    items: null,
    icons: [
      'isSymfony',
      'isJs',
      'isHtml',
      'isAws',
    ],
    extra: null
  },
  {
    title: 'Baby Gift List',
    category: 'Lifestyle',
    background: 'portfolio/baby',
    project: null,
    featured: false,
    about: 'A website for baby gift lists creation by adding items using special browser bookmarklet. Share lists and reserve items.',
    items: null,
    icons: [
      'isSymfony',
      'isJs',
      'isHtml',
      'isScreen',
    ],
    extra: null
  },
  {
    title: 'ERP Product API',
    category: 'retail',
    background: 'portfolio/pen',
    project: null,
    featured: false,
    about: 'A product search API of large promotional products distributor. Collaboration with Product Data team and IT Architect to setup the requirements and transform xml data files into a well-structured, normalised, fast, secure, developer-friendly and stateless RESTful API.',
    items: [
      '18+ API methods',
      'Automatic sync data with AWS S3',
      'Parsing products data from XML',
      'Architecture for REST API and Database',
    ],
    icons: [
      'isSymfony',
      'isAws',
      'isApi2',
    ],
    extra: null
  },
  {
    title: 'Language Schools',
    category: 'education',
    background: 'portfolio/lingua',
    project: 'linguaschools',
    featured: true,
    about: 'A new brand website for chain of Spanish language schools with custom CMS to manage courses, destinations and all dynamic info. Support of several languages (English, German, Dutch, etc) with own domains (.com, .de, .nl, etc) for SEO purposes.',
    items: [
      'Custom built CMS Pulling media content from external sources',
      'Multi domain and multi lingual architecture',
      'Integration with quotation tool',
    ],
    icons: [
      'isSymfony',
      'isJs',
      'isHtml',
      'isApi',
      'isSpeed',
    ],
    extra: null
  },
  {
    title: 'Monitoring Dashboard',
    category: 'engineering',
    background: 'portfolio/microchip',
    project: null,
    featured: false,
    about: 'A system for remote control and monitoring of transformer rectifiers. Private dashboard for customers of Corrosion Electronics to access data in real-time, receive alerts and export reports.',
    items: [
      'Real-time data access',
      'Background export into xls',
      'Wizard to connect new stations',
      'Separate DBs for each station',
    ],
    icons: [
      'isNote',
      'isSymfony',
      'isVue',
      'isApi2',
      'isSquare',
    ],
    extra: null
  },
  {
    title: 'Teacher search portal',
    category: 'directory',
    background: 'portfolio/teacher',
    project: 'lessonsaustralia',
    featured: true,
    about: 'A portal for easy search for qualified teachers nearby according to studying preferences.',
    items: [
      'Location- and subject-based search',
      'Advanced filters that save time ',
      'Rich teacher profile with embeddable media',
      'Online, Studio, and At Your Home lessons',
      'Integration with Google Maps ',
    ],
    icons: [
      'isHtml',
      'isJs',
      'isSymfony',
    ],
    extra: {
      image: 'lessonsaustralia',
      stats: [
        { value: 1500, text: 'hours' },
        { value: '3000+', text: 'teachers' },
        { value: 3500, text: 'users' },
      ]
    }
  },
  {
    title: 'Vocalists Social Network',
    category: 'music',
    background: 'portfolio/audio',
    project: null,
    featured: false,
    about: 'A music collaborative workspace which connects producers and vocalists. Dedicated developers for on-going work (2+ years).',
    items: null,
    icons: [
      'isSymfony',
      'isJs',
      'isHtml',
      'isApi',
    ],
    extra: null
  },
  {
    title: 'Video Stock Website',
    category: 'media',
    background: 'portfolio/camera',
    project: null,
    featured: false,
    about: 'A video stock BTB platform which allows to find and order collections of video clips. Elastic Search Engine for smart keywords search.',
    items: null,
    icons: [
      'isSymfony',
      'isJs',
      'isAws',
    ],
    extra: null
  },
  {
    title: 'Online Store',
    category: 'ecommerce',
    background: 'portfolio/card',
    project: null,
    featured: false,
    about: 'An eCommerce solution for large Australian home appliance retailer with custom backend system to manage inventory, products, brands, taxonomy, promotions.',
    items: null,
    icons: [
      'isSymfony',
      'isJs',
      'isHtml',
      'isSwan',
      'isApi',
    ],
    extra: null
  },
  {
    title: 'Security SaaS software',
    category: 'security',
    background: 'portfolio/code',
    project: 'spp',
    featured: true,
    about: 'A SaaS software to manage ads and threats block lists to reduce malware infection and other security vulnerabilities. Syncing block lists with Privoxy based proxy servers.',
    items: [
      'Threats management',
      'Paid subscriptions',
      'Syncing threats with proxy servers',
      'Real-time graphs for monitoring security',
    ],
    icons: [
      'isNote',
      'isSymfony',
      'isVue',
      'isSquare',
      'isApi2',
    ],
    extra: {
      image: 'spp',
      stats: [
        { value: 900, text: 'hours' },
        { value: 5, text: 'months to MVP' },
        { value: 4, text: 'developers' },
      ]
    }
  },
]
