import React, { useEffect, useState } from 'react'
import { getSectionIfActive, scrollToContactUs } from '../../helpers'

import Estimate from '../../../static/icons/estimate.svg'
import '../../styles/components/overlay/estimate-btn.scss'

export default () => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const visibleUpdate = () => {
      const isMobile = window.innerWidth < 375
      const isServices = getSectionIfActive('services-section')
      setVisible(!isMobile && !isServices)
    }

    visibleUpdate()
    setInterval(visibleUpdate, 250)
  })

  return (
    visible &&
      <button
        onClick={ scrollToContactUs }
        className="overlay-btn estimate-btn"
        aria-label="Estimate your project"
      >
        <Estimate className="overlay-btn__icon overlay-btn__icon-estimate" />
      </button>
  )
}

