import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from "@reach/router"

import { isMenuOpen } from '../../redux/actions/menuActions'

import Menu from '../../../static/icons/menu.svg'

import '../../styles/components/overlay/menu-btn.scss'

export default () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const handleClick = () => {
    if (window && location.pathname === '/') {
      // Disable fullpage scroll
      window.fullpage_api.setKeyboardScrolling(false)
    } else {
      document.querySelector('body').classList.add('noscroll')
    }

    dispatch(isMenuOpen(true))
  }

  return (
    <button
      onClick={ handleClick }
      className="overlay-btn menu-btn"
      aria-label="Open menu"
    >
      <Menu className="overlay-btn__icon" />
    </button>
  )
}

