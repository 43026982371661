export const setVhUnit = () => {
  let vh = window.innerHeight * 0.01

  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

export const isObjectEmpty = (obj) => {
  for(let key in obj) {
    if(obj.hasOwnProperty(key))
      return false
  }

  return true
}

/**
 * It changes the menu color to blue when menu icon is inside passed sections
 *
 * @param {Array<string>} sections - section's classnames
 */
export const changeOverlayColor = classes => {
  const overlay = document.querySelector('.overlay')
  const isBlue = overlay.classList.contains('overlay--blue')
  let needChangeToBlue = false

  for (let i = 0; i < classes.length; i++) {
    const section = document.querySelector(`.${classes[i]}`)

    if (section) {
      const { top, bottom } = section.getBoundingClientRect()

      if (top <= 50 && bottom > 50) {
        needChangeToBlue = true
        break
      }
    }
  }

  if (!isBlue && needChangeToBlue) {
    overlay.classList.add('overlay--blue')
  } else if (isBlue && !needChangeToBlue) {
    overlay.classList.remove('overlay--blue')
  }
}


export const handleKeyNav = (keyboard, { prevSite, nextSite }) => {
  switch(keyboard) {
    case 'ArrowLeft':
      window.location.href = prevSite
      break
    case 'ArrowRight':
      window.location.href = nextSite
      break
    default:
      break
  }
}

/**
 * Loads script and executes callback
 * @param {string} url
 * @param {function} cb
 */
export const loadScript = (url, cb) => {
  const isClient = typeof window === 'object'

  if (isClient) {
    let script = window.document.createElement('script')

    script.onload = function() {
      typeof cb === 'function' && cb()
    }

    script.onerror = function() {
      console.log("Error loading " + this.src)
    }

    script.src = url
    script.async = true

    document.head.append(script)
  }

}

export const splitByChunks = (items, chunkSize) => {
  return items.reduce((chunks, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)
    if (!chunks[chunkIndex]) chunks[chunkIndex] = []
    chunks[chunkIndex].push(item); return chunks
  }, [])
}

export const getSectionIfActive = (sectionClass) => {
  const classes = `.active.section.${ sectionClass }`
  return document.querySelector(classes)
}

export const scrollToContactUs = () => {
  const contactUs = getSectionIfActive('contact-us')

  if (contactUs) {
    const input = 'input[name=name]'
    contactUs.querySelector(input).focus()
  } else {
    window.fullpage_api?.moveTo('contact-us')
    window.scrollTo(0, document.body.scrollHeight)
  }
}
